<template>
  <v-card flat class="border-1 border-color--grey">
    <v-card-title class="d-flex align-center">
      <avatar :user="item.from" icon-only :size="30" />
      <v-btn
        class="text-none primary--text fw-700 fs-16"
        depressed
        link
        text
        @click="
          $router.push({
            name: 'view-order',
            params: { id: item.id },
            query: { redirect_uri: $route.fullPath }
          })
        "
      >
        Order No. OP-0{{ item.id }}0
      </v-btn>
      <v-chip
        label
        class="mr-2"
        color="transparent"
        :text-color="color_by_status"
      >
        {{ item.status | ucwords }}
      </v-chip>
      <v-chip
        label
        class="mr-3 primary--text"
        color="transparent"
        v-if="item.responses.length > 0"
      >
        {{ item.responses.length }} Responses
      </v-chip>
      <v-spacer />
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            depressed
            v-bind="attrs"
            v-on="on"
            class="text-none"
            @click.stop=""
          >
            <v-icon color="primary">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            @click.stop="
              $router.push({
                name: 'view-order',
                params: { id: item.id },
                query: { redirect_uri: $route.fullPath }
              })
            "
          >
            <v-list-item-title class="primary--text">
              <v-icon left color="primary">mdi-play-box-outline</v-icon>
              View Order
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="canCancel(item)"
            @click.stop="emit_action('cancelOrder')"
          >
            <v-list-item-title class="primary--text">
              <v-icon color="primary" left>mdi-cancel</v-icon>
              Cancel Order
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="canDelete(item)"
            @click.stop="emit_action('deleteOrder')"
          >
            <v-list-item-title class="primary--text">
              <v-icon color="primary" left>mdi-clipboard-alert</v-icon>
              Delete Order
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="canCompleted(item)"
            @click.stop="emit_action('completedOrder')"
          >
            <v-list-item-title class="primary--text">
              <v-icon left color="primary">
                mdi-checkbox-marked-circle-outline
              </v-icon>
              Set Order as Completed
            </v-list-item-title>
          </v-list-item>
          <slot name="extra_manage_list" />
        </v-list>
      </v-menu>
    </v-card-title>
    <div
      :class="{ 'flex-no-wrap': mdAndUp, 'flex-wrap': !mdAndUp }"
      class="d-flex align-center justify-space-between mb-3"
    >
      <v-sheet class="fullwidth">
        <v-slide-group class="pa-3" show-arrows>
          <v-slide-item
            v-for="template in item.orderattachments"
            :key="template.id"
          >
            <v-card
              outlined
              color="grey"
              class="ma-1"
              flat
              height="120"
              width="120"
            >
              <Attachment
                :item="template"
                prefer-thumb
                styles="width: auto;max-height:auto;"
              ></Attachment>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <div class="d-flex flex-column text-right">
        <v-card-title class="d-block primary--text fw-700 fs-20">
          {{ item.product.title | ucwords }}
        </v-card-title>
        <v-card-subtitle
          v-if="item.product.category && item.product.graphic_type"
        >
          <v-chip
            color="transparent"
            class="ml-1 rounded-0 primary--text fw-500 fs-14 pr-0"
          >
            {{ item.product.category.title | ucwords }}
            <v-icon class="mx-1">mdi-chevron-right</v-icon>
            {{ item.product.graphic_type.title | ucwords }}
          </v-chip>
        </v-card-subtitle>
        <v-btn
          color="white"
          max-width="200"
          class="primary--text ml-auto fw-700 text-none mr-3"
          @click="
            $router.push({
              name: 'view-order',
              params: { id: item.id },
              query: { redirect_uri: $route.fullPath }
            })
          "
        >
          See More
        </v-btn>
        <!-- <v-row dense align="center" class="pr-3" v-if="is_seller_side"> -->
        <!-- <v-spacer />
          <v-btn
            elevation="1"
            color="white"
            height="40px"
            style="margin-right: 10px"
            v-for="user in item.orderhandlers"
            :key="user.id"
          >
            <v-avatar class="mr-3" size="30" tile v-bind="attrs" v-on="on">
              <v-img  :lazy-src="settings.loader" :src="user.image_url">
                <v-icon color="red" @click="unassigned_from(user)">
                  mdi-delete-circle-outline
                </v-icon>
              </v-img>
            </v-avatar>
            <span
              class="primary--text fw-600 fs-14"
              style="text-transform: capitalize"
            >
              {{ user.fullname | ucwords }}
            </span>
          </v-btn> -->
        <!-- <v-tooltip top v-for="user in item.orderhandlers" :key="user.id">
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar class="mr-3" size="40" tile v-bind="attrs" v-on="on">
                                <v-img  :lazy-src="settings.loader" :src="user.image_url">
                                    <v-icon color="red" @click="unassigned_from(user)">
                                        mdi-delete-circle-outline
                                    </v-icon>
                                </v-img>
                            </v-avatar>
                        </template>
                        <span>{{ user.fullname | ucwords }}</span>
                    </v-tooltip> -->
        <!-- <v-menu
            top
            close-on-content-click
            :nudge-width="150"
            v-if="filter_allowed_users.length > 0"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-avatar
                    class="mr-3"
                    style="
                      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
                        0 1px 1px 0 rgba(0, 0, 0, 0.14),
                        0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
                    "
                    size="40"
                    tile
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    <v-icon color="primary">mdi-account-plus-outline</v-icon>
                  </v-avatar>
                </template>
                <span>Assigned to</span>
              </v-tooltip>
            </template>
            <v-list dense :max-height="400" style="overflow-y: auto">
              <v-list-item
                v-for="user in filter_allowed_users"
                :key="user.id"
                @click="assign_to(user)"
              >
                <v-list-item-avatar>
                  <v-img  :lazy-src="settings.loader" :src="user.image_url" />
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ user.fullname | ucwords }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        <!-- </v-row> -->
        <slot name="extra-right" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { offers_mixin } from '../mixin'
import _cloneDeep from 'lodash/cloneDeep'
import _diffBy from 'lodash/differenceBy'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderList',
  mixins: [offers_mixin],
  props: {
    order: Object,
    ratio: { type: Number, default: 1 },
    hasShare: { type: Boolean, default: false },
    maxWidth: { type: [String, Number], default: 250 },
    height: { type: [String, Number], default: 200 },
    displayLimit: { type: Number, default: 4 },
    allowedUsers: { type: Array, default: () => [] },
    hasComplete: { type: Boolean, default: true },
    hasCancel: { type: Boolean, default: false },
    hasDelete: { type: Boolean, default: false }
  },
  data: () => ({
    item: null
  }),
  computed: {
    ...mapGetters(['user']),
    is_seller_side() {
      if (!this.item) return false
      let ids = this.item.orderhandlers.map((i) => {
        return i.id
      })
      if (ids && ids.includes(this.user.id)) return true
      return this.user.id === this.item.product.user_id
    },
    selected_previews() {
      return this.item && this.item.orderattachments
        ? this.item.orderattachments.slice(0, this.displayLimit)
        : []
    },
    remaining() {
      return this.item && this.item.orderattachments
        ? this.item.orderattachments.length - this.displayLimit
        : 0
    },
    filter_allowed_users() {
      return _diffBy(this.allowedUsers, this.item.orderhandlers, 'id')
    },
    color_by_status() {
      let arr = {
        to_pay: 'orange',
        ongoing: 'blue',
        completed: 'green',
        cancelled: 'red'
      }
      return arr[this.item.status]
    }
  },
  watch: {
    order: {
      handler(val) {
        this.item = _cloneDeep(val)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.$emit('test', this.item)
  },
  methods: {
    emit_action(action) {
      this.$emit(action, this.item)
    },
    assign_to(user) {
      this.$emit('assign-to', { order_id: this.item.id, user_id: user.id })
    },
    unassigned_from(user) {
      this.$emit('unassigned-from', {
        order_id: this.item.id,
        user_id: user.id
      })
    },
    canCancel(item) {
      return item.status.toLowerCase() === 'ongoing' && this.hasCancel
    },
    canDelete(item) {
      return item.status.toLowerCase() === 'cancelled' && this.hasDelete
    },
    canCompleted(item) {
      return item.status.toLowerCase() === 'ongoing' && this.hasComplete
    }
  }
}
</script>

<style scoped lang="scss">
.card-header {
  border: none;
}
</style>
